import React from "react";
import { createRoot } from 'react-dom/client';
import "./simplythenews.css";
import SimplyNews from "./SimplyNews";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import {Helmet } from 'react-helmet';

const client = new ApolloClient({
 uri: "http://64.20.41.29:4000",
 cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
 <ApolloProvider client={client}>
 <SimplyNews />
 </ApolloProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);


/*
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
*/
