function About() {


const container = { width: 800, margin: '50px auto'}


const rawHTML = ` 

<h1 style="text-align: center;"><span style="color: #ff0000;">ABOUT</span></h1><p style="text-align: left;"><span style="color: #000000;">As with most everything, SimplyTheNews came about because I was tired of 
having to cross-check multiple news sources daily to I could feel comfortable I was getting the whole story, filtering out bias from each source, and just plain feeling comfortable that I had a handle on what was going on. I wanted 
something like this system to fill my need of being able to see all the news "compared" side-by-side so I could, with less time and effort, still reach that same comfort level that I knew what was going on.&nbsp;</span></p> <p 
style="text-align: left;"><span style="color: #000000;">My name is Andre, and I've been building websites, databases, and computer systems professionally for over 30 years.&nbsp; And if there is one thing I cannot stand, it is 
doing the same thing over and over again when I can program a computer to do it for me.&nbsp; My morning routine used to be, make my coffee, open the web browser, goto my favorite 5 or 6 news websites, and read the top stories from 
each site.&nbsp; I'm a political junkie, so of course, when a hot political story would pop up (quite frequently lately here in the USA) I would then have to add a few more news websites as each site has their own political bias, and I 
just want to get the facts.&nbsp; Sadly, the days of Walter Kronkite are over. It's up to us to identify the bias in each story from each source, and then figure out the actual underlying facts ourselves.&nbsp; But, it is our 
personal responsibility to sort out fact from fiction.&nbsp;</span></p> <p style="text-align: left;"><span style="color: #000000;">So, this website is the result of my trying to program a computer to ease my morning routine some, 
while actually increasing my exposure to news and much more easily being able to contrast similar stories from multiple sources to feret out the bias in each and come up with the root facts of a story.&nbsp;<br /><br />And while I 
was at it, I also always wanted to be able to go back in time and see how a story evolves, or just follow specific subjects over time.&nbsp; So, this site has that ability as well.&nbsp;</span></p> <p style="text-align: 
left;"><span style="color: #000000;">The Artificial Intelligence that runs the website is learning all the time, and hopefully, is getting better and better at identifying the "same" story and grouping them together.&nbsp;&nbsp;<br 
/><br />I sincerely hope you find this new tool as useful as I do.&nbsp; I would appreciate your feedback, good bad or ugly. Please feel free to email me using the "contact"&nbsp; button.&nbsp;<br /><br />And if you do find this 
useful please consider donating to the project.&nbsp; Unfortunatly, Artificial Intelligence requires some powerful computers, and add to that the storage space needed to keep this growing history of news stories, and the costs for 
this project add up quickly.&nbsp; I can only imagine that this tool will get more and more useful as time goes on, as the AI's will be constantly improving, and the 'history' continues to grow!<br /><br />Thank you for visiting, 
and I look forward to hearing any suggestions or criticisms you may have.<br /><br />- Andre</span></p> <p style="text-align: left;">&nbsp;</p>
<p style="text-align: left;">&nbsp;</p>

`;



return (

<div style={container}> <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div> </div> );

};
export default About;

