/***************************************************************************************************************** 
SimplyNews.js Version 1.00  ATN 2022-09-20

Initial Version under version control
******************************************************************************************************************/

// all imports go here
import React, { useState, useEffect } from "react";
import ContentArbitrator from "./ContentArbitrator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Date1Modal from "./Date1Modal";
import Date2Modal from "./Date2Modal";
import { v5 as uuidv5 } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import  useCookie  from 'react-use-cookie';
import { setCookie } from 'react-use-cookie';
import CryptoJS from 'crypto-js';
import { parseISO } from 'date-fns';
import { Helmet } from "react-helmet";

// The beginning of the main react function
//
// IT ALL starts here!
function SimplyNews() {




const SessionID4 = "5b51ad3e-e8d8-4588-a9b9-015e119997e5";

var S5text = "https://simplythenews.org/" + SessionID4; 

var SessionID5 = uuidv5(S5text, SessionID4);





// First thing we do is set up debugging using debug cookie
// check if url has debug  parameter (?debug=true ?debug=false)
// if so, create/set cookie accordingly
if (getParameterByName('debug') === 'true') {
	console.log("Debugging turned ON");
	setCookie('debug','true', { path: '/', days: 365 });
	}
if (getParameterByName('debug') === 'false') {
	console.log("Debugging turned off");
	setCookie('debug','false', { path: '/' });
	}
// now create state variable reading cookie
// only if cookie exists and contains 'true' will debug be enabled
const [Debug, setDebug] = useCookie('debug');



// let's repeat the process for the shousource cookie
if (getParameterByName('source') === 'true') {
	debug("Set to ShowSource");
	setCookie('ShowSource','true', { path: '/', days: 365 });
	}
if (getParameterByName('source') === 'false')
	setCookie('ShowSource','false', { path: '/' });
const [ShowSource, setShowSource] = useCookie('ShowSource');
if (ShowSource === 'true')
	debug("Sources will be shown");
else
	debug("Sources hidden");



// let's repeat the process for the beta cookie
if (getParameterByName('beta') === 'true') {
	debug("Set to Beta Mode");
	setCookie('beta','true', { path: '/', days: 365 });
	}
if (getParameterByName('beta') === 'false')
	setCookie('beta','false', { path: '/' });
const [Beta, setBeta] = useCookie('beta');
if (Beta === 'true')
	debug("Beta Mode ON");



// Let's see if we have a valid encrypted url
// try and decrypt the href.. if it is valid load that page, else load the home page

var bytes  = CryptoJS.AES.decrypt(window.location.href, SessionID5);
try {
	var originalText = bytes.toString(CryptoJS.enc.Utf8);
	}
catch	{
	var originalText = '';
	debug("Invalid URL -- will load root page");
	}


// now lets decode the url path -- if it exists
var urlmode = 0;

var pathArray = window.location.pathname.split('/');
debug("Depth of path = " + pathArray.length);
for (var i = 0; i < pathArray.length; i++) {
  debug("Path Level " + i + " = " +  pathArray[i]);
}

var urlkey  = decodeURIComponent(pathArray[1]);
var urldata = decodeURIComponent(pathArray[2]);

var myMode = 0;
var myClass = 'top';
var myPeriod = 'day';
var myID = '';
var myLimit = 50;
var myDate2 = currentdate(2);
var myDate1 = currentdate(1);



try {
	var keybytes = CryptoJS.AES.decrypt(urlkey,"This is my Key!");
	var key = keybytes.toString(CryptoJS.enc.Utf8);
	}
catch
	{
	var keybytes = "does not compute";
	var key = "does not compute";
	}

if (uuidValidate(key)) {
	try {
		debug("User ID (uuidv4) = " + key);
		var userkey = uuidv5( "https://simplythenews.org/" + key, key);
		debug("User ID (uuidv5) = " + userkey);
		var urlbytes = CryptoJS.AES.decrypt(urldata,userkey);
		var url = urlbytes.toString(CryptoJS.enc.Utf8);
		debug("Computed URL --> " + url);
		pathArray = url.split("/");
		for (i=0;i<pathArray.length;i++) {
			debug("url level " + i + " = " + pathArray[i]);
			}
			myPeriod = pathArray[1];
			myDate2 = pathArray[2];
			myClass = pathArray[3];
			switch (myPeriod) {
				case 'day':
					myDate1 = myDate2;
					break;
				case '3day':
					myDate1 = mydate(myDate2,-3,1);
					break;
				case 'week':
					myDate1 = mydate(myDate2,-7,1);
					break;
				case 'month':
					myDate1 = mydate(myDate2,-30,1);
					break;
				default:
					debug("INVALID unit type in URL -- loading home page");
					debug("received \n|" + myPeriod + "|");
					break;
				}
			if (pathArray.length === 5) {
				myMode = 10;
				myID = pathArray[4];
				}
			else {
				myMode =  0;
			}
			if (myClass === "top")
				myLimit = 50;
			else
				myLimit = 10000;
	}
/*
for (i = 2; i < pathArray.length; i++) 
	urldata = urldata + pathArray[i];
*/

	catch {
		debug("Decoding the URL Failed.. Invalid URL");
		}
	}






// initialize state variables
const [Date1, setDate1] = useState(myDate1);
const [Date2, setDate2] = useState(myDate2);
const [TimePeriodButton, setTimePeriodButton] = useState(myPeriod);
const [ClassButton, setClassButton] = useState(myClass);
const [Date1Toggle, setDate1Toggle] = useState(false);
const [Date2Toggle, setDate2Toggle] = useState(false);
const [CustomToggle, setCustomToggle] = useState(false);
const [Mode, setMode] = useState(myMode);
const [Limit, setLimit] = useState(myLimit);
const [Revision, setRevision] = useState('1');
const [URL, setURL] = useState('');
const [ID, setID] = useState(myID);
const [Group, setGroup] = useState(-1);

const day = 86400000;


// const [selectedCategory, setSelectedCategory] = useState("All");
// const { loading, error, data } = useQuery(GET_KEYWORDS_QUERY);
// if (error) return <p>Error</p>;
// if (loading) return <p>Loading...</p>;
 return (

<div className="wrapper">

<Helmet>
<meta property="og:url"                content="http://www.simplythenews.org" />
<meta property="og:type"               content="website" />
<meta property="og:title"              content="- Simply The News -" />
<meta property="og:description"        content="- Finding the same stories across multiple sources -" />
<meta property="og:image"              content="%PUBLIC_URL%/favicon.ico" />
</Helmet>
<div className="header"  onChange={SomeChange()}>
<div className="banner-padding"></div>
<center>
<div className="banner-date">
<div><span className="Date1-disabled" id="date1" onClick={() => DoDate1Modal()}>{new Date(Date1).toDateString()}</span></div>
<div><span className="Date2-enabled" id="date2" onClick={() => DoDate2Modal()}>{new Date(Date2).toDateString()}</span></div>
</div>

<div className="banner-center">Simply The News</div>
<div className="banner-nav"> 
<input readOnly id="day"    type="radio" name="tc" value="day"    checked={TimePeriodButton === 'day'}    onClick={() => TimePeriodClick('day')}  /><label htmlFor="day">day</label> 
<input readOnly id="3day"   type="radio" name="tc" value="3day"   checked={TimePeriodButton === '3day'}   onClick={() => TimePeriodClick('3day')} /><label htmlFor="3day">3day</label> 
<input readOnly id="week"   type="radio" name="tc" value="week"   checked={TimePeriodButton === 'week'}   onClick={() => TimePeriodClick('week')} /><label htmlFor="week">week</label> 
<input readOnly id="month"  type="radio" name="tc" value="month"  checked={TimePeriodButton === 'month'}  onClick={() => TimePeriodClick('month')} /><label htmlFor="month">month</label>
<input readOnly id="custom" type="radio" name="tc" value="custom" checked={TimePeriodButton === 'custom'} onClick={() => TimePeriodClick('custom')} /><label htmlFor="custom">custom</label>
</div>

<div className="banner-class" >
<input readOnly id="top"           type="radio" name="bc" value="top"           checked={ClassButton === 'top'}           onClick={() => ClassButtonClick('top')}   /><label htmlFor="top">Top Stories</label> 
<input readOnly id="World"         type="radio" name="bc" value="World"         checked={ClassButton === 'World'}         onClick={() => ClassButtonClick('World')} /><label htmlFor="World">World</label> 
<input readOnly id="US"            type="radio" name="bc" value="US"            checked={ClassButton === 'US'}            onClick={() => ClassButtonClick('US')} /><label htmlFor="US">US</label> 
<input readOnly id="Politics"      type="radio" name="bc" value="Politics"      checked={ClassButton === 'Politics'}      onClick={() => ClassButtonClick('Politics')} /><label htmlFor="Politics">Politics</label>
<input readOnly id="Health"        type="radio" name="bc" value="Health"        checked={ClassButton === 'Health'}        onClick={() => ClassButtonClick('Health')} /><label htmlFor="Health">Health</label>
<input readOnly id="Finance"       type="radio" name="bc" value="Finance"       checked={ClassButton === 'Finance'}       onClick={() => ClassButtonClick('Finance')} /><label htmlFor="Finance">Finance</label>
<input readOnly id="Entertainment" type="radio" name="bc" value="Entertainment" checked={ClassButton === 'Entertainment'} onClick={() => ClassButtonClick('Entertainment')} /><label htmlFor="Entertainment">Entertainment</label>
<input readOnly id="Sports"        type="radio" name="bc" value="Sports"        checked={ClassButton === 'Sports'}        onClick={() => ClassButtonClick('Sports')} /><label htmlFor="Sports">Sports</label>
<input readOnly id="Tech"          type="radio" name="bc" value="Tech"          checked={ClassButton === 'Technology'}    onClick={() => ClassButtonClick('Technology')} /><label htmlFor="Tech">Tech</label>
<input readOnly id="Religion"      type="radio" name="bc" value="Religion"      checked={ClassButton === 'Religion'}      onClick={() => ClassButtonClick('Religion')} /><label htmlFor="Religion">Religion</label>
<input readOnly id="Crime"         type="radio" name="bc" value="Crime"         checked={ClassButton === 'Crime'}         onClick={() => ClassButtonClick('Crime')} /><label htmlFor="Crime">Crime</label>
<input readOnly id="Space"         type="radio" name="bc" value="Space"         checked={ClassButton === 'Space'}         onClick={() => ClassButtonClick('Space')} /><label htmlFor="Space">Space</label>
</div>
</center>
<div className="banner-padding"></div>
</div>

<div className="content" id="content">
<ContentArbitrator ShowSource={ShowSource} debug={debug} Beta={Beta} displaydetails={displaydetails} displaymode={Mode} myurl={URL} myID={ID} mygroup={Group} myunit={TimePeriodButton} myclass={ClassButton} myrev={Revision} mylimit={Limit} sdate={Date1} edate={Date2}/>
</div>

<div className="footer">
<div><span className="footerbuttons" onClick={() => footerClick(1)}>Mission</span></div>
<div><span className="footerbuttons" onClick={() => footerClick(2)}>About</span></div>
<div><span className="footerbuttons" onClick={() => footerClick(3)}>How it works</span></div>
<div><span className="footerbuttons" onClick={() => footerClick(4)}>How to use</span></div>
<div><span className="footerbuttons" onClick={() => footerClick(5)}>Donate</span></div>
<div><span className="footerbuttons" onClick={() => footerClick(6)}>Contact us</span></div>
</div>
<Date1Modal DateToggle={Date1Toggle} handleClose={() => ProcessDateChange(1)}><DatePicker selected={parseISO(Date1)} onChange={(date) => setDate1(date.toISOString())}  startOpen={true}  shouldCloseOnSelect={false} withPortal fixedHeight /></Date1Modal>
<Date2Modal DateToggle={Date2Toggle} handleClose={() => ProcessDateChange(2)}><DatePicker selected={new Date(Date2)} onClickOutside={() => ProcessDateChange(2)} onChange={(date) => setDate2(new Date(date).toISOString())}  startOpen={true}  shouldCloseOnSelect={false} withPortal fixedHeight /></Date2Modal>
</div>

 );
// end of return 

// ************************************************************        functions start here          ************************************************//
//
//

function computeurl(unit,date2,myclass,id) {
// for testing, here we create a unique v4 uuid, and then v5 uuid is created with known stuff so we can decode
// it.. again, eventually every user will get a unique v4 id, then all urls created by that user will have a v5 id
// which can be decrypted back to its component parts using the v4 key
// kinda the poor mans RSA public/private key :-)
// the /822de984.... is where the v4 key will reside for each user
//const SessionID4 = uuidv4();

// Encrypt -- This eventually needs to live in the database so the webserver/client never touches the v4 keys!!!!

// REMEMBER: Final URL will be in the format https://simplythenews.org/{user uuidV5}/ and then whatever is computed below
//           The user uuidv5 is cleartext so one part of the key (public uuidv5) to decrypt is provided in the URL
//           to decrypt, the system will need to find the users hidden uuidV4 + the supplied uuidv5 and it will reveal the following 
//           if a valid url.. If it is a valid url the system will load that, and also make a note of what user generated that url for tracking etc purposes

// URL will be in following formats
// for class urls
// {unit}/{date2 in UTC/ISO}/{class}
// e.g. /day/2022-09-20T00:00:00.432Z/top

// for group urls
// {unit}/{date2 in UTC/ISO}/group/{article ID}
// e.g. /day/2022-09-20T00:00:00.324Z/group/12345-65-ab3421-23467

// for custom urls (I.E saved searched)
// /custom/{date1 in UTC/ISO}/{date2 in UTC/ISO}/{search string}
// e.g. /custom/2022-08-01T00:00:00Z/2022-09-20T00:00:00Z/Trump

var url = "/" + unit + "/" + date2 + "/";

if (id === "-") {
	url = url + myclass;
	}
else	{
	url = url + myclass + "/"+ id;
	}


var ciphertext = CryptoJS.AES.encrypt(url, SessionID5).toString();

var cypher4 = CryptoJS.AES.encrypt( SessionID4, "This is my Key!").toString();


// window.history.pushState(null,"","/" + SessionID4 + "/"+ encodeURIComponent(ciphertext) );

debug("Unencrypted URL  ->   " + url);
debug("URL to Send      ->    http://www.simplythenews.org/" + encodeURIComponent( cypher4 ) + "/" + encodeURIComponent( ciphertext ));

return "http://www.simplythenews.org/" + encodeURIComponent( cypher4 ) + "/" + encodeURIComponent( ciphertext );
}


// move all logging functions to the debug function, which just checks if Debug is set 
// If true, then outputs whatever is sent to it to the console
// we will also set this function as a property for all called children so they can debug as well
function debug(message) {
if (Debug === 'true')
	console.log(message);
} 



// This is the function that decodes the calling URL.. We will decde the url and then determine
// if we need to load a specific article group or class
// eventually we will encrypt/decrypt all urls for both tracking and to keep people from attempting to 
// hack the website
function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}



function DoDate1Modal() {
setMode(0);
if (TimePeriodButton === "custom")
	setDate1Toggle(true);
else
	alert("Beginning date cannot be set in this mode\nIn Day/3Day/Week/Month mode\nthis date will be automatically computed from the end date\nChange that date to modify the news you wish to see");
}

function DoDate2Modal() {
setMode(0);
setDate2Toggle(true);
}

function displaydetails(group,url) {
	setID(url);
	setURL(url);
	setGroup(group);
	setMode(10);
	window.history.pushState({},"",computeurl(TimePeriodButton,Date2,ClassButton,url));
}


function footerClick(page) {
setMode(page);
}

function TimePeriodClick(period) {
debug("In TimePeriodClick function -- period ---> " + period);
debug("                               Date1  ---> " + Date1);
debug("                               Date2  ---> " + Date2);

if (period === "custom") {
	alert("This feature is not implemented yet.");
	return;
}

setMode(0);
setTimePeriodButton(period);




if (period === "day") setDate1(mydate(Date2,0,1));

if (period === "3day") setDate1(mydate(Date2,-3,1));

if (period === 'week') setDate1(mydate(Date2,-7,1));

if (period === "month") setDate1(mydate(Date2,-30,1));

if (period === "custom") document.getElementById("date1").className = "Date1-enabled";
	else document.getElementById("date1").className = "Date1-disabled";
window.history.pushState({},"",computeurl(period,Date2,ClassButton,"-"));
}

function mydate(date,offset,mode) {
const day = 86400000;
debug("mydate current date ---> " + date);
// apparently date thinks it is a string, so you need the +date to force conversion to number for addition
var tempdate = new Date(Date.parse(date));
// if (mode === 1) tempdate = tempdate.setHours(0,0,0,0);
// if (mode === 2) tempdate = tempdate.setHours(23,59,59,59);
tempdate = new Date(+tempdate + offset*day).toISOString();
debug("mydate new date    ----> " + tempdate);
return tempdate;
}

function ClassButtonClick(group) {
setMode(0);
setClassButton(group);
if (group === 'top') setLimit(50);
	else setLimit(10000);
window.history.pushState({},"",computeurl(TimePeriodButton,Date2,group,"-"));
}

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}


function currentdate(p){
var today = new Date().toISOString();
/*
debug("Current local date : " + today);
debug("original date string : " + today.toISOString());
// debug("utc date is -----> " + utctoday);
today = convertTZ(today, "UTC");
var urldate = today.toISOString().substring(0,10);
debug("New date string : " + today.toISOString());
debug("utc date there is ---->>>> " + today);
debug("URLdate = " + urldate); 

if (p == 1)
	today = today.setHours( 0,0,0,0 );

else
	today = today.setHours(23,59,59,59);

 today = new Date(today);
*/
// return today.toDateString();
debug("Returned date --> " + today);
return today;
}

function date1Clicked(d) {
alert("date 1 clicked");
}

function TimePeriodChange(p) {
alert("New time period --- " + p);
}

function ClassButtonChange(p) {
alert("New Class --------- " + p);
}

function ProcessDateChange(m) {;


if (m === 1) {
	if (TimePeriodButton === "day") setDate1(mydate(Date2,0,1));
	if (TimePeriodButton === "3day") setDate1(mydate(Date2,-3,1));
	if (TimePeriodButton === 'week') setDate1(mydate(Date2,-7,1));
	if (TimePeriodButton === "month") setDate1(mydate(Date2,-30,1));
	setDate2Toggle(false);
}
else
	setDate1Toggle(false);

//window.location.assign(computeurl());
window.history.pushState({},"",computeurl(TimePeriodButton,Date2,ClassButton,"-"));
}


function SomeChange() {

}


}
export default SimplyNews;
