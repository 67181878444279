



function ArticleByClasslist(props) {



 function displayarticle(t,d) {
props.displaydetails(t,d);
};


const Process = data => {
	const row = [];

	for (let group = 0;group < data.length; group++) {
		if (data[group]["articlesInTopic"].length === 0)
			continue;
		const item = data[group]["articlesInTopic"][0];
		row.push(<div className="grid-item" key={data[group]["topicnum"]}><div className="articlelistcard" onClick={() => displayarticle(data[group]["topicnum"],item.ID)}><i className="small"><center>({data[group]["articlesInTopicAggregate"]["count"]} articles)</center></i><h4>{item.title}</h4></div></div>);
		}
	return row;
};

return (
<div>
<div className="grid-container">{Process(props.articles)}</div>
</div>
);
}

export default ArticleByClasslist;




