import { gql, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

function ArticleDetail(props) {

  let linksource = "";

  const Process = data => {
    const row = [];

    try {
      var toptitle = data[0]["title"];
      var topurl = data[0]["url"];
      var toppub = data[0]["pubdate"];
      var loop = data[0]["inTopiCtopicsConnection"]["edges"][0]["node"]["articlesInTopic"];
    }

    catch {
      console.log("Errr -- article not found");
      return (<div>Error -- Not found</div>);
    }
    console.log(toptitle);
    console.log(topurl);
    console.log(toppub);
    console.log(loop);

    row.push(<Helmet><title>{toptitle}</title><meta property="og:description" content="{(loop.length) articles}" /></Helmet>);


    if (loop.length === 0)
      return (<div>ARTICLE NOT FOUND -- ERROR!! -- THIS SHOULD NOT HAPPEN!!!!</div>);;
    for (let a = 0; a < loop.length; a++) {
      const item = loop[a];
      if (props.ShowSource === 'true')
        linksource = item.url;
      else
        linksource = "";
      row.push(<div className="grid-item" key={item.ID}><div className="articlelistcard" onClick={() => window.open(item.url, "_blank")}><b>{item.title}</b><br /><br />Published: {item.pubdate}<br />{linksource}</div></div>);
    }
    return row;

  };


  /*
  // Use this so you can open the components in debug and view the query and return data to map it correctly for this function!!!
  const Process = data => {
   return <div>data</div>;
  };
  */

  const ARTICLE_BY_ID = gql`
query   ArticleByID($mydate : Date!, $myunit : String!, $myID : String!) { 
    articles(where: { ID: $myID }) {
    ID
    title
    url
    pubdate
    inTopiCtopicsConnection(
      where: { node: { unit: $myunit , AND: [{ date: $mydate }] } }
    ) {
      edges {
        node {
          articlesInTopic(options: { sort: [{ pubdate: DESC }] }) {
            ID   
            title
            url
            pubdate
          }
        }
      }
    }
  }
}
`;


  const { loading, error, data } = useQuery(ARTICLE_BY_ID, {
    variables: { myunit: props.myunit, mydate: props.mydate, myID: props.myID }
  });
  if (error) return <p>Error</p>;
  if (loading) return <p>......Please be patient -- Gathering News Articles.......</p>;

  return (<div>{Process(data.articles)}</div>);


}

export default ArticleDetail;
