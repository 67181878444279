import ArticlesByClass from "./ArticlesByClass";
import ArticleDetail from "./ArticleDetail";
import Mission from "./Mission";
import About from "./About";
import Howwork from "./Howwork";
import Howuse from "./Howuse";
import Donate from "./Donate";
import Contact from "./Contact";


function ContentArbitrator(props) {

	// window.history.pushState(null, "", "http://www.simplythenews.org/day-2022-09-11/?source=true");

	function debug(message) {
		props.debug(message);
	}

	debug("In ContentArbitrator");
	debug("MyClass            = " + props.myclass + " is a " + typeof props.myclass);
	debug("MyUnit             = " + props.myunit +  " is a " + typeof props.myunit);
	debug("MyRev              = " + props.myrev  +  " is a " + typeof props.myrev);
	debug("MyLimit            = " + props.mylimit + " is a " + typeof props.mylimit);
	debug("Sdate (Date1)      = " + props.sdate +  " is a " + typeof props.sdate);
	debug("Edate (Date2)      = " + props.edate +   " is a " + typeof props.edate);
	debug("Mode               = " + props.displaymode + " is a " + typeof props.displaymode);
	//const urldate = props.edate.substring(0,10);
	
	switch (props.displaymode) {


		case 0: 
			// window.history.pushState(null,"","/"+props.myunit+"/"+urldate+"/"+props.myclass+"/");
			// return (<div><ArticlesByTopic myunit={props.myunit} edate={props.edate} /></div>);
			return (<div><ArticlesByClass ShowSource={props.ShowSource} Beta={props.Beta} myclass={props.myclass} myunit={props.myunit} myrev={props.myrev} mylimit={props.mylimit} edate={props.edate} displaydetails={props.displaydetails} /></div>);

		case 1:
			return (<div><Mission /></div>);

		case 2:
			return (<div><About /></div>);

		case 3:
			return (<div><Howwork /></div>);

		case 4:
			return (<div><Howuse /></div>);

		case 5:
			return (<div><Donate /></div>);

		case 6:
			return (<div><Contact /></div>);

		case 10:
			//window.history.pushState(null,"","/"+props.myunit+"/"+urldate+"/group/"+props.myID+"/");
			return (<div><ArticleDetail ShowSource={props.ShowSource} Beta={props.Beta} myID={props.myID} myclass={props.myclass} myunit={props.myunit} myrev={props.myrev} mydate={props.edate} mygroup={props.mygroup} myurl={props.myurl} /></div>);

		default:
			return (<div>DANGER WILL ROBINSON!!!!  DOES NOT COMPUTE!!</div>);
			break;	
	}
	
}

export default ContentArbitrator;
