function Mission() {


const container = { width: 800, margin: '50px auto'}


const url = "https://www.paypal.com/donate/?hosted_button_id=QMXWEXUZXAVUJ";



return (

<div style={container}>{window.open(url, '_blank', 'noopener,noreferrer')}</div> );

};
export default Mission;

