function Contact() {


const container = { width: 800, margin: '50px auto'}


const rawHTML = ` 

<h1 style="text-align: center;"><span style="color: #ff0000;">CONTACT</span></h1> <p>If you have any suggestions, feedback, comments, or ctriticisms please feel free to drop me an email.&nbsp;</p> <p>&nbsp;</p> <p>And if you find 
or encounter an error on the site, please send me an email. Please include the URL of the page (just copy/paste the address URL into the email -- I would appreciate that greatly!)<br /><br /><br /></p> <p>Thank you in advance for 
reaching out.&nbsp;&nbsp;</p> <p>&nbsp;</p> <p>&nbsp;- Andre</p>
<p>&nbsp;</p>

`;



return (

<div style={container}>
<div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
<br /><br />
<div><p><a href = "mailto: webmaster@simplythenews.org">Drop me an Email</a></p></div>
</div> );

};
export default Contact;

