function Howwork() {


const container = { width: 800, margin: '50px auto'}


const rawHTML = ` 

<h1 style="text-align: center;"><span style="color: #ff0000;">HOW IT WORKS</span></h1> <p><span style="color: #000000;">The system has a list of known sources of news. At present, there are 98 sources from such places as Fox News, 
ABC News, CBS, NBC, BBC, The Guardian, New York Times, Seattle Times, etc.&nbsp; You will also notice that there are many foreign news sources, such as The Sydney Times, News India, etc.&nbsp; And there are some specialtiy news 
sources, such as MotorSport News (I'm a motorsport junky).&nbsp;</span></p> <p><span style="color: #000000;">The system is constantly monitoring these sources for new stories.&nbsp; When it finds new stories, it 'reads' the 
original story, uses Artificial Intelligence to summarize the story, and then goes about figuring out what caterory the story best fits in. I,E. A story about StarLink satellite would end up in the "space" 
category.&nbsp;</span></p> <p>Another AI compares all the stories and figures out what stories are the "same" and then groups them together.&nbsp;</p> <p>The AI (Natural Language Processing) for each step is a different AI, 
customized for the specific task it is doing. The summary AI has been trained to do summaries better than a general purpose AI. The AI that figures out what category a news story belongs in has been specifically trained to do such, 
as has the AI that compares summaries of news stories and figures out which stories are talking about the same thing.&nbsp;</p> <p>While the above is going on, there is a database that is storing all this information.&nbsp; 
It does not store the actual article from the source. Once the AI has "read" the article and summarized it, the database throws out the original article but keeps the AI's summary. And the AI's are used to store the information 
they conclude in the database about each article. For example, when the AI determines that the article on Starlink is talking about "Space" it informs the database that this article is about "space". And when the AI that groups the 
articles together realizes that 8 articles are all talking about the same Starlink rocket launch, it notifies the database so the database knows that all these articles are talking about the same thing.&nbsp;</p> <p>So, when you go 
to the website you are presented with artcles "grouped" together with their peer articles from other sources.&nbsp;<br /><br />And, of course, since this is a database, you can change dates or date ranges and see what the database 
"knows" about the news on any specific date or range of dates, or about any search words you want to enter.&nbsp;</p> <p>&nbsp;</p> <p>Feel free to contact me if you have any questions, suggestions, or comments.&nbsp;</p> 
<p>&nbsp;</p> <p>- Andre</p>
<p>&nbsp;</p>

`;



return (

<div style={container}> <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div> </div> );

};
export default Howwork;

