function Mission() {


const container = { width: 800, margin: '50px auto'}


const rawHTML = `

<h1 style="text-align: center;"><span style="color: #ff0000;"><strong>OUR MISSION</strong></span></h1> <p>&nbsp;</p> <p><span style="color: #000000;"><strong>SimplyTheNews was created to help abate the growing need we all suffer 
from; that of information overload.&nbsp; We are constantly bombarded by news.&nbsp; And if you are anything like me, regardless of the source of the news, I'm always looking to verify all the news I hear with other 
sources.&nbsp;&nbsp;</strong></span></p> <p><span style="color: #000000;"><strong style="color: #000000;">Sadly, due to </strong><span style="color: #000000;"><strong>information</strong></span><strong style="color: #000000;"> 
overload, we've become lazy.&nbsp; It is much easier to delegate our own personal responsibility to double check everything and just consume our news from a few hand picked sources.&nbsp; We may even go as far as to completely 
disregard other sources.</strong></span></p> <p><span style="color: #000000;"><strong style="color: #000000;">But it is our personal responsibility to confirm for ourselves what is true and what is not! And by listening to only a 
few select sources haven't we delegated our personal responsibility to those media outlets alone?</strong></span></p> <p><span style="color: #000000;"><strong style="color: #000000;">SimplyTheNews was designed with that in 
mind.&nbsp; If multiple sources are all independently reporting the same story, although that certainly is not a guarantee that all the facts are correct, it certainly does lend credibility to the underlying story.<br /><br 
/>Conversely, if only one source is talking about a story, or multiple sources are all repeating the same identical story from the same reporter, that would tend to lower the credibility of the story. Again, it doesn't 
guarantee the story is wholly or partly false, but it certainly would raise some questions.&nbsp;</strong></span></p> <p><span style="color: #000000;"><strong style="color: #000000;">In a nutshell, SimplyTheNews is constantly 
combing the internet using multiple news outlets (Currently 98 sources as of this writing). It gathers all the news from each source, and then using artificial intelligence custom built for SimplyTheNews, compares the stories and 
then categorizes them and groups them together when they are the same story from different sources.<br /><br />Welcome to having much of your homework done for you! Personal Responsibility has finally gotten easier again! No more 
having to check multiple websites to see if a story is being talked about. They are presented to you conveniently grouped together!<br /><br /></strong></span></p> <p><span style="color: #000000;"><strong style="color: 
#000000;">And, of course, we take it one step further.&nbsp; Wouldn't it be nice to be able to go back in time and see how a story evolves? Or just see a snapshot of news from any day or period of time? It's as simple as clicking a 
mouse! or typing in what you are looking for!</strong></span></p> <p>&nbsp;</p>
<h2><span style="color: #000000;"><strong style="color: #000000;">Welcome to the news, simplified. Welcome to SimplyTheNews</strong></span></h2>

`;



return (

<div style={container}> <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div> </div> );

};
export default Mission;

