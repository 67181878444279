
import { useEffect } from "react";
import ReactPortal from "./ReactPortal";
import  "./datemodal.css";

function Date1Modal( {children, DateToggle, handleClose}) {
  useEffect(() => {
    const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);


if (!DateToggle) return null;

return (
<ReactPortal wrapperId="Date1ModalContainer">
<div className="DateModal">
<div className="DateModal-content">{children}</div>
</div>
</ReactPortal>
);
}

export default Date1Modal;

