function Howuse() {


const container = { width: 800, margin: '50px auto'}


const rawHTML = ` 

<h1 style="text-align: center;"><span style="color: #ff0000;">HOW TO USE</span></h1> <p><span style="color: #000000;">When you first enter the website, you'll notice the website has 3 'sections'. </span></p> <p><span style="color: 
#000000;">The top section is where you do all your selections so you can find the news you are looking for.&nbsp;<br /></span></p>
<p><span style="color: #000000;">On each side of the Simply The News logo you'll find a date. The left date can be considered&nbsp; "from" date and the right date would be the "to" date. The left date is computed automatically for 
you when you select Day/3Day/Week or Month.&nbsp; It is simply that many days "before" the right date. In "custom" mode, which is where you can enter a date range, keywords to search for, etc, is the only time you will be able to 
enter a "left" date.&nbsp;</span></p> <p><span style="color: #000000;">As mentioned above, directly below the SimplyTheNews logo are 5 buttons that determine what time period you are interested in.&nbsp; If you just want to see the 
latest news of the day, select "day". If you want to see all the news stories 'grouped' together from the past 3days, week, or month, select the appropriate button.&nbsp;</span></p> <p><span style="color: #000000;">The Custom 
button is the odd man out. When selecting Custom, the left date will be enabled so you can select the "from" date for your searches.&nbsp; And the system's 'grouping' of articles will be disabled. Since the timeframe search is up 
to you, as is the search keywords, grouping by 'same story' doesn't work currently.&nbsp;</span></p> <p><span style="color: #000000;">Below the time period buttons are the classification buttons.&nbsp; The "Top Stories" will always 
list the top 50 largest 'groups' of articles within the time period you selected, regardless of each articles individual classification. All the other classification buttons respect the articles classification, so if you choose 
"space" you won't see any articles the system thinks are about "politics".&nbsp; And in these classifications, all articles within that classification and time period will be shown.&nbsp; The list is always sorted so stories that 
have the most sources talking about them will be at the top. As you scroll down, you'll see the many articles the system has where only a single source is talking about it.&nbsp;&nbsp;</span></p> <p><span style="color: 
#000000;">The center section is where all the articles appear.&nbsp; If you are looking at the 'groups' you'll see in each 'block', top center, a (# articles) where # represents how many other individual articles/sources are 
talking about this same story.&nbsp; If you click on the block, the system will list out each article in the group, sorted by the publication date of the article -- the top article will always be the latest published article. And 
the system alwyas stores articles in UTC time.&nbsp; The system is fully date aware, so it will convert your TimeZone behind the scenes into UTC time.<br /></span></p> <p><span style="color: #000000;">If you click on any article in 
the list, the system will open up another browser window and bring you to the source's website so you can read the entire original article yourself.&nbsp;<br /><br /></span></p> <p><span style="color: #000000;">The bottom section 
of the website are the links (such as this on, how to use).&nbsp;</span></p> <p>&nbsp;</p> <p><span style="color: #000000;">Please feel free to contact me with any ideas, suggestions, comments, criticisms, etc..&nbsp;</span></p> 
<p>&nbsp;</p>
<p><span style="color: #000000;">- Andre</span></p>

`;



return (

<div style={container}> <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div> </div> );

};
export default Howuse;

