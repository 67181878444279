import { gql, useQuery } from "@apollo/client";
import ArticleByClassList from "./ArticleByClassList";



const GET_ARTICLESBYCLASS = gql`


query ArticlesByClassTopic($mydate : Date!, $myunit : String!, $myrev : BigInt!, $mylimit: Int!, $myclass : String!) {
  topics(
    where: { date: $mydate, unit: $myunit, revision: $myrev }
    options: { sort: [{ topicnum: ASC }], limit: $mylimit }
  ) {
    topicnum
    articlesInTopic(
      where: { inClassificatioNclassifications_SOME: { name: $myclass } }
    ) {
      ID
      title
      url
    }
    articlesInTopicAggregate {
      count
    }
  }
}


`;



function ArticlesByClass(props) {


        function debug(message) {
                console.log(message);
        }

        debug("In ArticlesByClass");
        debug("MyClass            = " + props.myclass + " is a " + typeof props.myclass);
        debug("MyUnit             = " + props.myunit +  " is a " + typeof props.myunit);
        debug("MyRev              = " + props.myrev  +  " is a " + typeof props.myrev);
        debug("MyLimit            = " + props.mylimit + " is a " + typeof props.mylimit);
        debug("Sdate (Date1)      = " + props.sdate +  " is a " + typeof props.sdate);
        debug("Edate (Date2)      = " + props.edate +   " is a " + typeof props.edate);
        debug("Mode               = " + props.displaymode + " is a " + typeof props.displaymode);



 const { loading, error, data } = useQuery( GET_ARTICLESBYCLASS, { variables: { myunit: props.myunit, mydate: props.edate, myrev: props.myrev, mylimit: props.mylimit, myclass: props.myclass } 
 },);
 if (error) return <p>Error</p>;
 if (loading) return <p>......Please be patient -- Gathering News Articles.......</p>;



return (
<div className="articles">
<ArticleByClassList articles = {data.topics}  mydate={props.edate} myunit={props.myunit} myrev={props.myrev} displaydetails={props.displaydetails} />
</div>
);

}
export default ArticlesByClass;


